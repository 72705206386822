import { SVGProps } from 'react';

const IconBigDataSecurity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={338.023}
    height={326.762}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          d="M222.322-204.5h134.391v9.432H222.322z"
          transform="translate(-222.322 204.499)"
        />
      </clipPath>
      <clipPath id="b">
        <path
          d="M284.168-252v16.809a24.018 24.018 0 0 1-5.37 15.11l-2.215 2.738h65.739l-2.215-2.738a24.019 24.019 0 0 1-5.371-15.11V-252z"
          transform="translate(-276.583 252.003)"
        />
      </clipPath>
      <clipPath id="c">
        <path
          d="M283.517-252h56.776v28.581h-56.776z"
          transform="translate(-283.517 252.003)"
        />
      </clipPath>
      <clipPath id="d">
        <path
          d="M78.666-112h81.874v18.1H78.666z"
          transform="translate(-78.666 112)"
        />
      </clipPath>
      <clipPath id="e">
        <path
          d="M449.332-112h81.874v18.1h-81.874z"
          transform="translate(-449.332 112)"
        />
      </clipPath>
    </defs>
    <g data-name="Group 63">
      <path d="M251.852 261.363H85.71l13.117-12.653h139.911z" fill="#fff" />
      <path
        d="M251.852 261.363H85.71l13.117-12.653h139.911z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M233.392 253.59c0 2.6-30.084 4.717-67.195 4.717s-67.2-2.112-67.2-4.717 30.089-4.72 67.2-4.72 67.195 2.116 67.195 4.72"
        fill="#004e89"
      />
      <g opacity={0.5} clipPath="url(#a)" transform="translate(99.002 248.875)">
        <path
          d="M134.39 4.716c0 2.6-30.084 4.717-67.195 4.717s-67.2-2.112-67.2-4.717 30.089-4.72 67.2-4.72 67.195 2.116 67.195 4.72"
          fill="#fff"
        />
      </g>
      <path
        d="M251.851 273.092H85.711v-11.728h166.14z"
        fill="#1a2e35"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path d="M263.608 285.745H73.957l11.754-12.654h166.144z" fill="#fff" />
      <path
        d="M263.608 285.745H73.957l11.754-12.654h166.144z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M263.576 298.09H73.99v-12.345h189.586z"
        fill="#1a2e35"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M166.944 136.239V57.356h30.02V24.162m-18.29 32.823V24.163M169.19 148.81h138.2v-19.645h26.419m-171.394 18.968H30.993v-21H2.542M140.059 3.163v29.806h-16.934v18.969h21v63.678m88.743-24.387V48.55h11.516V30.26h9.484M93.318 87.165V54.648l-9.484-9.484V21.453m174.776 84.679h23.711V90.551h15.581v-8.806h13.548M69.608 111.551H44.543V91.906H9.316m83.323 126.003v17.613H81.123v19.645H47.93m171.389-34.549v18.291h26.419v-7.452h7.452v15.58h23.029"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M198.897 24.163a1.932 1.932 0 0 1-1.932 1.933 1.932 1.932 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.933 1.933 1.933 0 0 1 1.932 1.933"
        fill="#1a2e35"
      />
      <path
        d="M198.897 24.163a1.932 1.932 0 0 1-1.932 1.933 1.932 1.932 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.933 1.933 1.933 0 0 1 1.932 1.933z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M180.68 24.163a1.932 1.932 0 0 1-1.932 1.933 1.932 1.932 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.933 1.933 1.933 0 0 1 1.932 1.933"
        fill="#1a2e35"
      />
      <path
        d="M180.68 24.163a1.932 1.932 0 0 1-1.932 1.933 1.932 1.932 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.933 1.933 1.933 0 0 1 1.932 1.933z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M142.005 2.6a1.933 1.933 0 0 1-1.933 1.933A1.932 1.932 0 0 1 138.14 2.6a1.933 1.933 0 0 1 1.932-1.933 1.933 1.933 0 0 1 1.933 1.933"
        fill="#1a2e35"
      />
      <path
        d="M142.005 2.6a1.933 1.933 0 0 1-1.933 1.933A1.932 1.932 0 0 1 138.14 2.6a1.933 1.933 0 0 1 1.932-1.933 1.933 1.933 0 0 1 1.933 1.933z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M85.78 20.475a1.933 1.933 0 0 1-1.933 1.933 1.932 1.932 0 0 1-1.932-1.933 1.933 1.933 0 0 1 1.932-1.933 1.933 1.933 0 0 1 1.933 1.933"
        fill="#1a2e35"
      />
      <path
        d="M85.78 20.475a1.933 1.933 0 0 1-1.933 1.933 1.932 1.932 0 0 1-1.932-1.933 1.933 1.933 0 0 1 1.932-1.933 1.933 1.933 0 0 1 1.933 1.933z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M11.248 91.906a1.932 1.932 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.933 1.933 1.933 0 0 1 1.932 1.933"
        fill="#1a2e35"
      />
      <path
        d="M11.248 91.906a1.932 1.932 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.933 1.933 1.933 0 0 1 1.932 1.933z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M4.531 127.074a1.933 1.933 0 0 1-1.933 1.933 1.932 1.932 0 0 1-1.932-1.933 1.933 1.933 0 0 1 1.932-1.933 1.933 1.933 0 0 1 1.933 1.933"
        fill="#1a2e35"
      />
      <path
        d="M4.531 127.074a1.933 1.933 0 0 1-1.933 1.933 1.932 1.932 0 0 1-1.932-1.933 1.933 1.933 0 0 1 1.932-1.933 1.933 1.933 0 0 1 1.933 1.933z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M50.03 254.797a1.933 1.933 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.932 1.932 1.932 0 0 1 1.932 1.932"
        fill="#1a2e35"
      />
      <path
        d="M50.03 254.797a1.933 1.933 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.932 1.932 1.932 0 0 1 1.932 1.932z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M279.136 246.655a1.933 1.933 0 0 1-1.933 1.932 1.932 1.932 0 0 1-1.932-1.932 1.932 1.932 0 0 1 1.932-1.932 1.933 1.933 0 0 1 1.933 1.932"
        fill="#1a2e35"
      />
      <path
        d="M279.136 246.655a1.933 1.933 0 0 1-1.933 1.932 1.932 1.932 0 0 1-1.932-1.932 1.932 1.932 0 0 1 1.932-1.932 1.933 1.933 0 0 1 1.933 1.932z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M336.718 128.782a1.933 1.933 0 0 1-1.933 1.933 1.933 1.933 0 0 1-1.932-1.933 1.932 1.932 0 0 1 1.932-1.932 1.933 1.933 0 0 1 1.933 1.932"
        fill="#1a2e35"
      />
      <path
        d="M336.718 128.782a1.933 1.933 0 0 1-1.933 1.933 1.933 1.933 0 0 1-1.932-1.933 1.932 1.932 0 0 1 1.932-1.932 1.933 1.933 0 0 1 1.933 1.932z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M314.293 81.333a1.933 1.933 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.932 1.932 0 0 1 1.933-1.932 1.932 1.932 0 0 1 1.932 1.932"
        fill="#1a2e35"
      />
      <path
        d="M314.293 81.333a1.933 1.933 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.932 1.932 0 0 1 1.933-1.932 1.932 1.932 0 0 1 1.932 1.932z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M257.053 30.207a1.933 1.933 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.932 1.932 1.932 0 0 1 1.932 1.932"
        fill="#1a2e35"
      />
      <path
        d="M257.053 30.207a1.933 1.933 0 0 1-1.932 1.933 1.933 1.933 0 0 1-1.933-1.933 1.933 1.933 0 0 1 1.933-1.932 1.932 1.932 0 0 1 1.932 1.932z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M192.228 234.975V218.17h-50.567v16.805a24.019 24.019 0 0 1-5.371 15.11l-2.215 2.738h65.739l-2.215-2.738a24.019 24.019 0 0 1-5.371-15.11"
        fill="#fff"
      />
      <g transform="translate(134.075 218.17)" clipPath="url(#b)">
        <path
          d="M4.481 28.581h56.777a23.93 23.93 0 0 1-3.105-11.772V0H7.581v16.809a23.939 23.939 0 0 1-3.1 11.772"
          fill="#004e89"
        />
        <g opacity={0.3} clipPath="url(#c)" transform="translate(4.481)">
          <path
            d="M0 28.581h56.777a23.93 23.93 0 0 1-3.105-11.772V0H3.1v16.809A23.939 23.939 0 0 1 0 28.581"
            fill="#fff"
          />
        </g>
        <path
          d="M4.481 10.862h56.777A23.93 23.93 0 0 1 58.153-.91v-16.809H7.581V-.91a23.939 23.939 0 0 1-3.1 11.772"
          fill="#1a2e35"
        />
      </g>
      <path
        d="M192.228 234.975V218.17h-50.567v16.805a24.019 24.019 0 0 1-5.371 15.11l-2.215 2.738h65.739l-2.215-2.738a24.019 24.019 0 0 1-5.371-15.11z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M261.251 223.471H72.64a7.312 7.312 0 0 1-7.312-7.312V81.554a7.313 7.313 0 0 1 7.312-7.313h188.612a7.312 7.312 0 0 1 7.312 7.313v134.605a7.312 7.312 0 0 1-7.312 7.312"
        fill="#1a2e35"
      />
      <path
        d="M261.251 223.471H72.64a7.312 7.312 0 0 1-7.312-7.312V81.554a7.313 7.313 0 0 1 7.312-7.313h188.612a7.312 7.312 0 0 1 7.312 7.313v134.605a7.312 7.312 0 0 1-7.313 7.312z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M261.251 223.47H72.64a7.312 7.312 0 0 1-7.313-7.309v-15.887h203.235v15.887a7.312 7.312 0 0 1-7.312 7.312"
        fill="#fff"
      />
      <path
        d="M261.251 223.47H72.64a7.312 7.312 0 0 1-7.313-7.309v-15.887h203.235v15.887a7.312 7.312 0 0 1-7.311 7.309z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M258.339 188.955H75.55V83.525h182.789z"
        fill="#fff"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M170.694 215.541a3.71 3.71 0 0 0-3.748-3.67 3.71 3.71 0 0 0-3.752 3.67 3.71 3.71 0 0 0 3.751 3.671 3.71 3.71 0 0 0 3.749-3.671"
        fill="#1a2e35"
      />
      <path
        d="M170.694 215.541a3.71 3.71 0 0 0-3.748-3.67 3.71 3.71 0 0 0-3.752 3.67 3.71 3.71 0 0 0 3.751 3.671 3.71 3.71 0 0 0 3.749-3.671z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M199.827 255.828h-65.753v-3h65.752z"
        fill="#fff"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M167.227 78.06c-9.194 8.572-32.728 17.223-60.167 35.579 0 0 1.714 62.03 60.167 95.935z"
        fill="#004e89"
      />
      <path
        d="M167.227 78.06c-9.194 8.572-32.728 17.223-60.167 35.579 0 0 1.714 62.03 60.167 95.935z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M167.226 78.06c9.194 8.572 32.729 17.223 60.167 35.579 0 0-1.714 62.03-60.167 95.935z"
        fill="#1a2e35"
      />
      <path
        d="M167.226 78.06c9.194 8.572 32.729 17.223 60.167 35.579 0 0-1.714 62.03-60.167 95.935z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M167.229 83.34c-11.1 7.7-30.33 15.045-55.756 32.054 0 0 1.588 57.48 55.753 88.9 54.165-31.419 55.754-88.9 55.754-88.9-25.426-17.009-44.57-24.354-55.751-32.054"
        fill="#1a2e35"
      />
      <path
        d="M167.229 83.34c-11.1 7.7-30.33 15.045-55.756 32.054 0 0 1.588 57.48 55.753 88.9 54.165-31.419 55.754-88.9 55.754-88.9-25.426-17.009-44.57-24.354-55.751-32.054z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M222.983 115.394c-25.426-17.01-44.57-24.35-55.754-32.054v120.953c54.165-31.419 55.754-88.9 55.754-88.9"
        fill="#004e89"
      />
      <path
        d="M222.983 115.394c-25.426-17.01-44.57-24.35-55.754-32.054v120.953c54.162-31.419 55.754-88.899 55.754-88.899z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M269.191 159.054H64.7v-13.237h204.491z"
        fill="#1a2e35"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M68.728 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M66.566 148.667a1.1 1.1 0 0 0 .79.332 1.089 1.089 0 0 0 1.1-1.078 1.055 1.055 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M74.099 147.92a1.357 1.357 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M71.937 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M79.47 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M77.308 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.921 16.921 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M84.841 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M82.679 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.056 1.056 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M90.212 147.92a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M88.049 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M95.583 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M93.421 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.055 1.055 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M100.954 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M98.792 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M106.325 147.92a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M104.162 148.667a1.106 1.106 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M111.696 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M109.534 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M117.067 147.92a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M114.905 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M122.438 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M120.276 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.921 16.921 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M127.809 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M125.647 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M133.18 147.92a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M131.018 148.667a1.106 1.106 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M138.551 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M136.389 148.667a1.1 1.1 0 0 0 .79.332 1.089 1.089 0 0 0 1.1-1.078 1.055 1.055 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M68.728 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M66.566 157.216a1.1 1.1 0 0 0 .79.332 1.089 1.089 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M74.099 156.469a1.357 1.357 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M71.937 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M79.47 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M77.308 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.921 16.921 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M84.841 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M82.679 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.052 1.052 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M90.212 156.469a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M88.049 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M95.583 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M93.421 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M100.954 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M98.792 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M106.325 156.469a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M104.162 157.216a1.106 1.106 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M111.696 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M109.534 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M117.067 156.469a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M114.905 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M122.438 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M120.276 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.921 16.921 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M127.809 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M125.647 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M133.18 156.469a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M131.018 157.216a1.106 1.106 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.935 16.935 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M138.551 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M136.389 157.216a1.1 1.1 0 0 0 .79.332 1.089 1.089 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M198.063 147.92a1.357 1.357 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M195.901 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M203.435 147.92a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M201.272 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 16.921 16.921 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M208.806 147.92a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M206.643 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.056 1.056 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M214.176 147.92a1.357 1.357 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M212.014 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M219.548 147.92a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M217.385 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M224.919 147.92a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M222.756 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M230.289 147.92a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M228.127 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M235.66 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M233.498 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M241.032 147.92a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M238.869 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M246.402 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M244.24 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M251.773 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M249.611 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M257.145 147.92a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M254.982 148.667a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M262.515 147.92a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M260.353 148.667a1.1 1.1 0 0 0 .79.332 1.089 1.089 0 0 0 1.1-1.078 1.055 1.055 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M267.886 147.92a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M265.724 148.667a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M198.063 156.469a1.357 1.357 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M195.901 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M203.435 156.469a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M201.272 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 16.921 16.921 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M208.806 156.469a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M206.643 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.052 1.052 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M214.176 156.469a1.357 1.357 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.357 1.357 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M212.014 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M219.548 156.469a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M217.385 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M224.919 156.469a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M222.756 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M230.289 156.469a1.357 1.357 0 0 1-1.372 1.343 1.357 1.357 0 0 1-1.372-1.343 1.357 1.357 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M228.127 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M235.66 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M233.498 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M241.032 156.469a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M238.869 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M246.402 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M244.24 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M251.773 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M249.611 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M257.145 156.469a1.358 1.358 0 0 1-1.373 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M254.982 157.216a1.1 1.1 0 0 0 .79.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.041 17.041 0 0 0-1.614 1.441"
        fill="#1a2e35"
      />
      <path
        d="M262.515 156.469a1.357 1.357 0 0 1-1.372 1.343 1.358 1.358 0 0 1-1.372-1.343 1.358 1.358 0 0 1 1.372-1.342 1.357 1.357 0 0 1 1.372 1.342"
        fill="#fff"
      />
      <path
        d="M260.353 157.216a1.1 1.1 0 0 0 .79.332 1.089 1.089 0 0 0 1.1-1.078 1.051 1.051 0 0 0-.275-.695 16.935 16.935 0 0 0-1.615 1.441"
        fill="#1a2e35"
      />
      <path
        d="M267.886 156.469a1.358 1.358 0 0 1-1.373 1.343 1.357 1.357 0 0 1-1.371-1.343 1.357 1.357 0 0 1 1.371-1.342 1.358 1.358 0 0 1 1.373 1.342"
        fill="#fff"
      />
      <path
        d="M265.724 157.216a1.1 1.1 0 0 0 .789.332 1.09 1.09 0 0 0 1.1-1.078 1.048 1.048 0 0 0-.276-.695 17.026 17.026 0 0 0-1.613 1.441"
        fill="#1a2e35"
      />
      <path
        d="M150.247 134.536h34.647v-15.781a17.164 17.164 0 0 0-17.324-16.96 17.164 17.164 0 0 0-17.323 16.96zm38.1 6.761h-41.553a3.418 3.418 0 0 1-3.453-3.381v-19.161c0-13.08 10.869-23.721 24.229-23.721s24.229 10.641 24.229 23.721v19.161a3.417 3.417 0 0 1-3.452 3.381"
        fill="#fff"
      />
      <path
        d="M150.247 134.536h34.647v-15.781a17.164 17.164 0 0 0-17.324-16.96 17.164 17.164 0 0 0-17.323 16.96zm38.1 6.761h-41.553a3.418 3.418 0 0 1-3.453-3.381v-19.161c0-13.08 10.869-23.721 24.229-23.721s24.229 10.641 24.229 23.721v19.161a3.417 3.417 0 0 1-3.452 3.381z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M193.678 175.824h-52.9v-43.763h52.9z"
        fill="#004e89"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M172.73 150.234a5.7 5.7 0 0 0-5.76-5.638 5.7 5.7 0 0 0-5.76 5.638 5.622 5.622 0 0 0 3.02 4.964l-.968 9.939h7.4l-.967-9.939a5.623 5.623 0 0 0 3.035-4.964"
        fill="#1a2e35"
      />
      <path
        d="M172.73 150.234a5.7 5.7 0 0 0-5.76-5.638 5.7 5.7 0 0 0-5.76 5.638 5.622 5.622 0 0 0 3.02 4.964l-.968 9.939h7.4l-.967-9.939a5.623 5.623 0 0 0 3.035-4.964z"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M88.02 317.713c0 5-18.328 9.049-40.937 9.049s-40.936-4.051-40.936-9.049 18.328-9.049 40.936-9.049 40.937 4.051 40.937 9.049"
        fill="#004e89"
      />
      <g opacity={0.5} clipPath="url(#d)" transform="translate(6.147 308.664)">
        <path
          d="M81.873 9.049c0 5-18.328 9.049-40.937 9.049S0 14.047 0 9.049 18.328 0 40.936 0s40.937 4.051 40.937 9.049"
          fill="#fff"
        />
      </g>
      <path
        d="M25.815 308.456s-9.929 5.584-9.929 8.273 6.126 1.034 10.14-.831 7.182-3.3 7.182-7.029-7.393-.413-7.393-.413"
        fill="#004e89"
      />
      <path
        d="M25.815 308.456s-9.929 5.584-9.929 8.273 6.126 1.034 10.14-.831 7.182-3.3 7.182-7.029-7.393-.413-7.393-.413z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M73.347 308.456s9.929 5.584 9.929 8.273-6.127 1.034-10.14-.831-7.183-3.3-7.183-7.029 7.394-.413 7.394-.413"
        fill="#004e89"
      />
      <path
        d="M73.347 308.456s9.929 5.584 9.929 8.273-6.127 1.034-10.14-.831-7.183-3.3-7.183-7.029 7.394-.413 7.394-.413z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m29.829 217.043-4.225 92.449a5.607 5.607 0 0 0 4.225 1.24 5.52 5.52 0 0 0 3.38-1.862l14.365-75.488h2.957l15.209 76.936a7.033 7.033 0 0 0 2.958.827c1.479 0 3.8-1.861 3.8-1.861l-4.858-92.241z"
        fill="#1a2e35"
      />
      <path
        d="m29.829 217.043-4.225 92.449a5.607 5.607 0 0 0 4.225 1.24 5.52 5.52 0 0 0 3.38-1.862l14.365-75.488h2.957l15.209 76.936a7.033 7.033 0 0 0 2.958.827c1.479 0 3.8-1.861 3.8-1.861l-4.858-92.241z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m44.616 160.994-19.224 7.86 5.07 44.673H67.22l6.76-46.327-20.914-6.619z"
        fill="#004e89"
      />
      <path
        d="m44.616 160.994-19.224 7.86 5.07 44.673H67.22l6.76-46.327-20.914-6.619z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M67.643 217.042H29.829v-4.344h37.814z"
        fill="#1a2e35"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m29.196 216.215-6.55 2.688a20.921 20.921 0 0 0 .423 5.585c.633 2.482 1.478 7.859 2.112 8.893s3.8 3.1 4.225 2.482 2.535-18.821 2.535-18.821z"
        fill="#1a2e35"
      />
      <path
        d="m29.196 216.215-6.55 2.688a20.921 20.921 0 0 0 .423 5.585c.633 2.482 1.478 7.859 2.112 8.893s3.8 3.1 4.225 2.482 2.535-18.821 2.535-18.821z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M43.968 151.372v7.539l5.5 5.709 5.611-5.816v-7.54z"
        fill="#fff"
      />
      <path
        d="M43.968 151.372v7.539l5.5 5.709 5.611-5.816v-7.54z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M40.118 144.37s-.99-2.262-1.65-.862a4.31 4.31 0 0 0 1.1 4.416 3.555 3.555 0 0 0 2.53.646l-.55-3.23z"
        fill="#fff"
      />
      <path
        d="M40.118 144.37s-.99-2.262-1.65-.862a4.31 4.31 0 0 0 1.1 4.416 3.555 3.555 0 0 0 2.53.646l-.55-3.23z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M58.05 144.37s.99-2.262 1.65-.862a4.312 4.312 0 0 1-1.1 4.416 3.555 3.555 0 0 1-2.53.646l.55-3.231z"
        fill="#fff"
      />
      <path
        d="M58.05 144.37s.99-2.262 1.65-.862a4.312 4.312 0 0 1-1.1 4.416 3.555 3.555 0 0 1-2.53.646l.55-3.231z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M41.988 129.399s-4.62 1.939-4.62 5.17.66 6.893 1.76 8.293 1.98 2.369 1.98 2.369l.22-4.631 3.188-5.495s5.28 3.339 7.921 3.339a26.9 26.9 0 0 0 4.4-.323l.99 5.492 1.43-3.985v-3.659s2.64-2.477 2.09-4.093-9.458-6.677-14.958-5.385-4.4 2.909-4.4 2.909"
        fill="#1a2e35"
      />
      <path
        d="M41.988 129.399s-4.62 1.939-4.62 5.17.66 6.893 1.76 8.293 1.98 2.369 1.98 2.369l.22-4.631 3.188-5.495s5.28 3.339 7.921 3.339a26.9 26.9 0 0 0 4.4-.323l.99 5.492 1.43-3.985v-3.659s2.64-2.477 2.09-4.093-9.458-6.677-14.958-5.385-4.401 2.908-4.401 2.908z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M39.788 140.279s1.32 8.076 2.421 10.23a29.88 29.88 0 0 0 5.279 6.463c.77.539 3.63.108 4.731.108s4.29-6.463 4.841-7.647.879-11.31.879-11.31l-.989-1.831s-4.73 1.187-8.471-.323a12.886 12.886 0 0 1-5.28-3.554 16.393 16.393 0 0 1-1.651 5.816c-1.32 2.154-1.76 2.048-1.76 2.048"
        fill="#fff"
      />
      <path
        d="M39.788 140.279s1.32 8.076 2.421 10.23a29.88 29.88 0 0 0 5.279 6.463c.77.539 3.63.108 4.731.108s4.29-6.463 4.841-7.647.879-11.31.879-11.31l-.989-1.831s-4.73 1.187-8.471-.323a12.886 12.886 0 0 1-5.28-3.554 16.393 16.393 0 0 1-1.651 5.816c-1.32 2.154-1.76 2.048-1.76 2.048z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M48.259 141.14a9.465 9.465 0 0 0-4.29-1.077 8.075 8.075 0 0 0-3.74.754s0 3.663 1.21 5.062 4.731.97 5.721-.215 1.1-4.524 1.1-4.524"
        fill="#1a2e35"
      />
      <path
        d="M48.259 141.14a9.465 9.465 0 0 0-4.29-1.077 8.075 8.075 0 0 0-3.74.754s0 3.663 1.21 5.062 4.731.97 5.721-.215 1.099-4.524 1.099-4.524z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M49.799 141.14a9.466 9.466 0 0 1 4.291-1.078 8.079 8.079 0 0 1 3.74.754s0 3.663-1.21 5.062-4.731.97-5.721-.215-1.1-4.523-1.1-4.523"
        fill="#1a2e35"
      />
      <path
        d="M49.799 141.14a9.466 9.466 0 0 1 4.291-1.078 8.079 8.079 0 0 1 3.74.754s0 3.663-1.21 5.062-4.731.97-5.721-.215-1.1-4.523-1.1-4.523z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M48.259 141.139h1.54"
        fill="#fff"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m46.729 167.406 2.323 3.725-3.8 32.057 4.646 6.823 4.014-7.446-3.174-31.85 2.751-4.343-4.019-1.753z"
        fill="#1a2e35"
      />
      <path
        d="m46.729 167.406 2.323 3.725-3.8 32.057 4.646 6.823 4.014-7.446-3.174-31.85 2.751-4.343-4.019-1.753z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m49.469 164.619-3.08 5.278-5.5-7.325 3.081-3.662z"
        fill="#004e89"
      />
      <path
        d="m49.469 164.619-3.08 5.278-5.5-7.325 3.081-3.662z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m49.469 164.62 3.74 5.816 4.511-8.293-2.641-3.341z"
        fill="#004e89"
      />
      <path
        d="m49.469 164.62 3.74 5.816 4.511-8.293-2.641-3.341z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M25.392 168.854 1.944 199.67l20.7 14.891 2.958-5.171-6.338-12.2 8.661-7.859z"
        fill="#004e89"
      />
      <path
        d="M25.392 168.854 1.944 199.67l20.7 14.891 2.958-5.171-6.338-12.2 8.661-7.859z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m25.604 209.39 6.971-.206 5.281 4.756a2.587 2.587 0 0 1-.634 1.655c-.633.621-2.323.206-2.323.206s-1.268 3.102-3.592 3.102-8.661-4.342-8.661-4.342z"
        fill="#fff"
      />
      <path
        d="m25.604 209.39 6.971-.206 5.281 4.756a2.587 2.587 0 0 1-.634 1.655c-.633.621-2.323.206-2.323.206s-1.268 3.102-3.592 3.102-8.661-4.342-8.661-4.342z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m23.913 205.874-3.591 6.411m-1.054-15.097-2.746-7.032"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m73.98 167.199 23.665 32.471-20.707 14.891-2.958-5.171 6.338-12.2-9.929-8.067z"
        fill="#004e89"
      />
      <path
        d="m73.98 167.199 23.665 32.471-20.707 14.891-2.958-5.171 6.338-12.2-9.929-8.067z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m73.983 209.39-6.971-.206-5.281 4.756a2.587 2.587 0 0 0 .634 1.655c.63.621 2.318.208 2.318.208s1.268 3.1 3.591 3.1 8.661-4.343 8.661-4.343z"
        fill="#fff"
      />
      <path
        d="m73.983 209.39-6.971-.206-5.281 4.756a2.587 2.587 0 0 0 .634 1.655c.63.621 2.318.208 2.318.208s1.268 3.1 3.591 3.1 8.661-4.343 8.661-4.343z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m75.67 205.874 3.591 6.411m1.056-15.097 2.747-7.032"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M42.01 177.126H29.054v4.413l6.478 2.482 6.478-2.758z"
        fill="#004e89"
      />
      <path
        d="M42.01 177.126H29.054v4.413l6.478 2.482 6.478-2.758z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M67.923 177.126H54.967v4.413l6.478 2.482 6.478-2.758z"
        fill="#004e89"
      />
      <path
        d="M67.923 177.126H54.967v4.413l6.478 2.482 6.478-2.758z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M327.608 317.713c0 5-18.328 9.049-40.937 9.049s-40.936-4.051-40.936-9.049 18.328-9.049 40.936-9.049 40.937 4.051 40.937 9.049"
        fill="#004e89"
      />
      <g
        opacity={0.5}
        clipPath="url(#e)"
        transform="translate(245.735 308.664)"
      >
        <path
          d="M81.873 9.049c0 5-18.328 9.049-40.937 9.049S0 14.047 0 9.049 18.328 0 40.936 0s40.937 4.051 40.937 9.049"
          fill="#fff"
        />
      </g>
      <path
        d="M265.263 308.456s-9.929 5.584-9.929 8.273 6.126 1.034 10.14-.831 7.182-3.3 7.182-7.029-7.393-.413-7.393-.413"
        fill="#004e89"
      />
      <path
        d="M265.263 308.456s-9.929 5.584-9.929 8.273 6.126 1.034 10.14-.831 7.182-3.3 7.182-7.029-7.393-.413-7.393-.413z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M312.795 308.456s9.929 5.584 9.929 8.273-6.127 1.034-10.14-.831-7.183-3.3-7.183-7.029 7.394-.413 7.394-.413"
        fill="#004e89"
      />
      <path
        d="M312.795 308.456s9.929 5.584 9.929 8.273-6.127 1.034-10.14-.831-7.183-3.3-7.183-7.029 7.394-.413 7.394-.413z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m269.277 217.043-4.225 92.449a5.607 5.607 0 0 0 4.225 1.24 5.52 5.52 0 0 0 3.38-1.862l14.365-75.488h2.957l15.209 76.936a7.033 7.033 0 0 0 2.958.827c1.478 0 3.8-1.861 3.8-1.861l-4.858-92.241z"
        fill="#1a2e35"
      />
      <path
        d="m269.277 217.043-4.225 92.449a5.607 5.607 0 0 0 4.225 1.24 5.52 5.52 0 0 0 3.38-1.862l14.365-75.488h2.957l15.209 76.936a7.033 7.033 0 0 0 2.958.827c1.478 0 3.8-1.861 3.8-1.861l-4.858-92.241z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m284.064 160.994-19.224 7.86 5.07 44.673h36.758l6.76-46.328-20.914-6.618z"
        fill="#004e89"
      />
      <path
        d="m284.064 160.994-19.224 7.86 5.07 44.673h36.758l6.76-46.328-20.914-6.618z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m286.177 167.406 2.323 3.725-3.8 32.057 4.646 6.823 4.014-7.446-3.174-31.85 2.751-4.343-4.019-1.753z"
        fill="#1a2e35"
      />
      <path
        d="m286.177 167.406 2.323 3.725-3.8 32.057 4.646 6.823 4.014-7.446-3.174-31.85 2.751-4.343-4.019-1.753z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m288.918 164.619-3.08 5.278-5.5-7.325 3.08-3.662z"
        fill="#004e89"
      />
      <path
        d="m288.918 164.619-3.08 5.278-5.5-7.325 3.08-3.662z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m288.917 164.62 3.74 5.816 4.511-8.293-2.64-3.341z"
        fill="#004e89"
      />
      <path
        d="m288.917 164.62 3.74 5.816 4.511-8.293-2.64-3.341z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M281.459 177.126h-12.956v4.413l6.477 2.482 6.479-2.758z"
        fill="#004e89"
      />
      <path
        d="M281.459 177.126h-12.956v4.413l6.477 2.482 6.479-2.758z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M307.371 177.126h-12.956v4.413l6.478 2.482 6.478-2.758z"
        fill="#004e89"
      />
      <path
        d="M307.371 177.126h-12.956v4.413l6.478 2.482 6.478-2.758z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M307.091 217.042h-37.815v-4.344h37.814z"
        fill="#1a2e35"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m268.644 216.215-6.55 2.688a20.921 20.921 0 0 0 .423 5.585c.633 2.482 1.478 7.859 2.112 8.893s3.8 3.1 4.225 2.482 2.535-18.821 2.535-18.821z"
        fill="#1a2e35"
      />
      <path
        d="m268.644 216.215-6.55 2.688a20.921 20.921 0 0 0 .423 5.585c.633 2.482 1.478 7.859 2.112 8.893s3.8 3.1 4.225 2.482 2.535-18.821 2.535-18.821z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M283.417 151.372v7.539l5.5 5.709 5.611-5.816v-7.54z"
        fill="#fff"
      />
      <path
        d="M283.417 151.372v7.539l5.5 5.709 5.611-5.816v-7.54z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M279.566 144.37s-.99-2.262-1.65-.862a4.312 4.312 0 0 0 1.1 4.416 3.555 3.555 0 0 0 2.53.646l-.55-3.23z"
        fill="#fff"
      />
      <path
        d="M279.566 144.37s-.99-2.262-1.65-.862a4.312 4.312 0 0 0 1.1 4.416 3.555 3.555 0 0 0 2.53.646l-.55-3.23z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M297.498 144.37s.989-2.262 1.65-.862a4.312 4.312 0 0 1-1.1 4.416 3.557 3.557 0 0 1-2.531.646l.552-3.23z"
        fill="#fff"
      />
      <path
        d="M297.498 144.37s.989-2.262 1.65-.862a4.312 4.312 0 0 1-1.1 4.416 3.557 3.557 0 0 1-2.531.646l.552-3.23z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M281.436 129.399s-4.62 1.939-4.62 5.17.659 6.893 1.76 8.293 1.98 2.369 1.98 2.369l.219-4.626 3.189-5.5s5.28 3.339 7.921 3.339a26.878 26.878 0 0 0 4.4-.323l.99 5.492 1.431-3.985v-3.659s2.64-2.477 2.09-4.093-9.459-6.677-14.96-5.385-4.4 2.909-4.4 2.909"
        fill="#1a2e35"
      />
      <path
        d="M281.436 129.399s-4.62 1.939-4.62 5.17.659 6.893 1.76 8.293 1.98 2.369 1.98 2.369l.219-4.626 3.189-5.5s5.28 3.339 7.921 3.339a26.878 26.878 0 0 0 4.4-.323l.99 5.492 1.431-3.985v-3.659s2.64-2.477 2.09-4.093-9.459-6.677-14.96-5.385-4.4 2.908-4.4 2.908z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M279.236 140.279s1.32 8.076 2.421 10.23a29.88 29.88 0 0 0 5.279 6.463c.77.539 3.63.108 4.731.108s4.29-6.463 4.841-7.647.879-11.31.879-11.31l-.989-1.831s-4.73 1.187-8.47-.323a12.885 12.885 0 0 1-5.28-3.554 16.4 16.4 0 0 1-1.65 5.816c-1.322 2.154-1.762 2.048-1.762 2.048"
        fill="#fff"
      />
      <path
        d="M279.236 140.279s1.32 8.076 2.421 10.23a29.88 29.88 0 0 0 5.279 6.463c.77.539 3.63.108 4.731.108s4.29-6.463 4.841-7.647.879-11.31.879-11.31l-.989-1.831s-4.73 1.187-8.47-.323a12.885 12.885 0 0 1-5.28-3.554 16.4 16.4 0 0 1-1.65 5.816c-1.322 2.154-1.762 2.048-1.762 2.048z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M287.707 141.14a9.465 9.465 0 0 0-4.29-1.077 8.079 8.079 0 0 0-3.74.754s0 3.663 1.21 5.062 4.731.97 5.721-.215 1.1-4.524 1.1-4.524"
        fill="#1a2e35"
      />
      <path
        d="M287.707 141.14a9.465 9.465 0 0 0-4.29-1.077 8.079 8.079 0 0 0-3.74.754s0 3.663 1.21 5.062 4.731.97 5.721-.215 1.099-4.524 1.099-4.524z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M289.247 141.14a9.466 9.466 0 0 1 4.291-1.078 8.073 8.073 0 0 1 3.739.754s0 3.663-1.209 5.062-4.731.97-5.721-.215-1.1-4.523-1.1-4.523"
        fill="#1a2e35"
      />
      <path
        d="M289.247 141.14a9.466 9.466 0 0 1 4.291-1.078 8.073 8.073 0 0 1 3.739.754s0 3.663-1.209 5.062-4.731.97-5.721-.215-1.1-4.523-1.1-4.523z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="M287.707 141.139h1.54"
        fill="#fff"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m265.052 209.39 6.971-.206 5.281 4.756a2.587 2.587 0 0 1-.634 1.655c-.633.621-2.323.206-2.323.206s-1.268 3.102-3.592 3.102-8.661-4.342-8.661-4.342z"
        fill="#fff"
      />
      <path
        d="m265.052 209.39 6.971-.206 5.281 4.756a2.587 2.587 0 0 1-.634 1.655c-.633.621-2.323.206-2.323.206s-1.268 3.102-3.592 3.102-8.661-4.342-8.661-4.342z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m264.84 168.854-23.448 30.816 20.7 14.891 2.958-5.171-6.337-12.2 8.66-7.859z"
        fill="#004e89"
      />
      <path
        d="m264.84 168.854-23.448 30.816 20.7 14.891 2.958-5.171-6.337-12.2 8.66-7.859z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m263.361 205.874-3.591 6.411m-1.053-15.097-2.747-7.032"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m313.432 209.39-6.971-.206-5.281 4.756a2.587 2.587 0 0 0 .634 1.655c.629.621 2.318.208 2.318.208s1.268 3.1 3.591 3.1 8.661-4.343 8.661-4.343z"
        fill="#fff"
      />
      <path
        d="m313.432 209.39-6.971-.206-5.281 4.756a2.587 2.587 0 0 0 .634 1.655c.629.621 2.318.208 2.318.208s1.268 3.1 3.591 3.1 8.661-4.343 8.661-4.343z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m313.428 167.199 23.66 32.471-20.702 14.891-2.958-5.171 6.338-12.2-9.929-8.067z"
        fill="#004e89"
      />
      <path
        d="m313.428 167.199 23.66 32.471-20.702 14.891-2.958-5.171 6.338-12.2-9.929-8.067z"
        fill="none"
        stroke="#1a2e35"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
      <path
        d="m315.119 205.874 3.591 6.411m1.056-15.097 2.747-7.032"
        fill="none"
        stroke="#1a2e35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.333}
      />
    </g>
  </svg>
);

export default IconBigDataSecurity;
