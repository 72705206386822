import {
  Box, Typography, Grid, Stack,
} from '@mui/material';
import Button from '../components/app/Button';
import Container from '../components/app/Container';
import Testimonials from '../components/app/Testimonials';
import IconBigDataSecurity from '../components/icons/IconBigDataSecurity';
import IconBigInsight from '../components/icons/IconBigInsight';
import IconPersonHoldCreditCard from '../components/icons/IconPersonHoldCreditCard';
import IconPersonWriting from '../components/icons/IconPersonWriting';
import IconPublishBookHero from '../components/icons/IconPublishBookHero';

const howItWorks = [
  {
    title: 'Seamless online publishing tool',
    description: 'Whether you\'re just starting out as a writer or you\'re a fairly established creative, Litireso makes it easy to earn and publish creative works.',
    icon: IconPersonWriting,
  },
  {
    title: 'Faster payout; No withdrawal threshold.',
    description: 'We pay you 85% royalty on each sale and you can request your money at anytime. There is no limit to the amount you can withdraw.',
    icon: IconPersonHoldCreditCard,
  },
  {
    title: 'Insights on your book',
    description: 'Get detailed analytics on your book. Filter by number of purchases, views, reads, etc.',
    icon: IconBigInsight,
  },
  {
    title: 'Data Security',
    description: 'Your book and its contents are stored securely in cloud and you can delete them anytime you want. We have also put measures in place to prevent your book from getting plagiarised.',
    icon: IconBigDataSecurity,
  },
];

const PublishABook = () => (
  <Box sx={(theme) => ({
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  })}
  >
    <Container maxWidth="lg">
      <Grid container alignItems="center" justifyContent="space-between" columnGap={3}>
        <Grid item xs={12} md={6} marginBottom={{ xs: 5, md: 'unset' }}>
          <Typography variant="h3" component="h1" gutterBottom fontWeight="600">
            From Your Corner to Africa and the Globe
          </Typography>
          <Typography>
            Litireso makes it easy to earn and publish creative works globally.
          </Typography>

          <Stack columnGap={3} flexDirection="row" marginTop={3}>
            <Button href="/app/dashboard/publication">Upload my ebook</Button>
            <Button variant="outlined" href="/print-distribution">Sell my print book</Button>
          </Stack>
        </Grid>
        <Grid xs={12} md="auto">
          <IconPublishBookHero sx={{ width: '500px', maxWidth: '100%' }} />
        </Grid>
      </Grid>
    </Container>

    <Container maxWidth="md" sx={{ marginTop: 10, marginBottom: 10 }}>
      {howItWorks.map((item, index) => (
        <Grid
          container
          key={item.title}
          alignItems="center"
          marginBottom={7}
          flexDirection={index % 2 === 0 ? 'row' : 'row-reverse'}
        >
          <Grid item xs={12} md={6}><item.icon /></Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom variant="h5" fontWeight="600">{item.title}</Typography>
            <Typography>{item.description}</Typography>
          </Grid>
        </Grid>
      ))}
    </Container>

    <Container maxWidth="md">
      <Testimonials />
    </Container>
  </Box>
);

export default PublishABook;
