import { SVGProps } from 'react';

const IconPersonHoldCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 666.667 496.813"
    {...props}
  >
    <path
      d="M0 436.93h666.667v-.334H0zm580 21.127h19.868v.333H580v-.333m-149.964 3.951h11.59v-.333h-11.59zm98.745-16.333h45.59v.333h-45.59v-.333m-440.198 6.881h57.592v.333H88.583v-.333m69.462 0h20.77v.333h-20.77v-.333m56.727-4.14h124.902v-.333H214.772zM58.554.335c-4.014 0-7.278 3.265-7.278 7.276v361.91c0 4.012 3.264 7.276 7.278 7.276h257.465c4.01 0 7.276-3.264 7.276-7.277V7.61c0-4.01-3.266-7.275-7.276-7.275H58.554M316.019 377.13H58.554c-4.197 0-7.611-3.414-7.611-7.61V7.61c0-4.195 3.414-7.61 7.61-7.61H316.02c4.194 0 7.609 3.415 7.609 7.61v361.91c0 4.196-3.415 7.61-7.61 7.61M346.95.335c-4.013 0-7.277 3.265-7.277 7.276v361.91c0 4.012 3.264 7.276 7.276 7.276h257.466c4.012 0 7.277-3.264 7.277-7.277V7.61c0-4.01-3.265-7.275-7.277-7.275H346.949M604.415 377.13H346.949c-4.196 0-7.609-3.414-7.609-7.61V7.61c0-4.195 3.413-7.61 7.61-7.61h257.465c4.196 0 7.61 3.415 7.61 7.61v361.91c0 4.196-3.414 7.61-7.61 7.61"
      fill="#e7e8e5"
    />
    <path
      d="M295.335 426.861h10.529v-72.13h-10.53zm-213.067 9.735h216.645v-9.735H82.268z"
      fill="#ededeb"
    />
    <path d="M75.316 426.861h220.018v-72.13H75.316z" fill="#f3f2f1" />
    <path d="M89.55 408.483h191.552v-41.199H89.55z" fill="#ededeb" />
    <path
      d="M138.723 372.808h93.205a3.166 3.166 0 0 0 3.157-3.157v-.595a3.167 3.167 0 0 0-3.157-3.159h-93.205a3.168 3.168 0 0 0-3.158 3.159v.595a3.167 3.167 0 0 0 3.158 3.157"
      fill="#f3f2f1"
    />
    <path d="M70.492 354.73h224.842v-6.443H70.492z" fill="#ededeb" />
    <path
      d="M295.335 354.73h15.589v-6.443h-15.59zm-8.043-101.591H569.2v-216.2H287.292zM260.9 28.527h324.241a2.445 2.445 0 1 1 0 4.889H260.9a2.445 2.445 0 1 1 0-4.89"
      fill="#e2e2e0"
    />
    <path d="M276.843 253.139h287.473v-216.2H276.843z" fill="#ededeb" />
    <path d="M287.292 253.139H569.2v6.925H280.093l7.199-6.925" fill="#e2e2e0" />
    <path
      d="M259.433 253.139h287.475l-7.2 6.925H252.233l7.2-6.925"
      fill="#ededeb"
    />
    <path d="M291.472 238.508h258.215V51.567H291.472z" fill="#fafaf9" />
    <path
      d="M463.527 238.508 377.66 51.567h-40.535l85.868 186.941h40.534m-49.242 0L328.417 51.567h-15.802l85.868 186.941h15.802"
      fill="#fff"
    />
    <path d="M291.472 238.508h1.857V51.567h-1.857z" fill="#e2e2e0" />
    <path
      d="M574.695 202.567H569.2v-48.634l1.22-18.497c.173-2.637-.276-5.139-1.22-7.367V36.94h-9.875v-3.523h15.37v169.15m0-174.04h-15.37v-4.374h-9.809 25.179v4.375"
      fill="#f9f9f8"
    />
    <path
      d="M569.2 202.567h-3.208l3.208-48.634v48.634m0-74.498a15.49 15.49 0 0 0-4.884-6.288V36.94h-4.99 9.874v91.13m-9.875-94.654h15.37v-4.89h-15.37z"
      fill="#e6e6e4"
    />
    <path
      d="M564.316 121.781a15.579 15.579 0 0 0-4.99-2.53V36.939h4.99v84.842"
      fill="#ededeb"
    />
    <path
      d="M559.325 36.939h-9.809v-3.523h9.81v3.523m0-8.412h-9.81v-4.375h-9.808 19.618v4.375"
      fill="#f3f2f1"
    />
    <path d="M549.516 33.416h9.81v-4.89h-9.81z" fill="#e2e2e0" />
    <path
      d="M559.325 119.25a16.283 16.283 0 0 0-4.646-.667h-4.992V51.567h-.171V36.939h9.81v82.312"
      fill="#e8e9e6"
    />
    <path d="M549.516 118.583h.17V51.567h-.17z" fill="#efefed" />
    <path
      d="M549.516 36.939h-15.37v-3.523h15.37v3.523m0-8.412h-15.37v-4.375h-9.809 25.179v4.375"
      fill="#f9f9f8"
    />
    <path d="M534.145 33.416h15.371v-4.89h-15.37z" fill="#e6e6e4" />
    <path d="M534.145 51.567h15.371V36.938h-15.37z" fill="#ededeb" />
    <path d="M534.145 118.583h15.371V51.567h-15.37z" fill="#f4f4f2" />
    <path
      d="M534.145 36.939h-9.808v-3.523h9.808v3.523m0-8.412h-9.808v-4.375h-9.808 19.616v4.375"
      fill="#f3f2f1"
    />
    <path d="M524.337 33.416h9.808v-4.89h-9.808z" fill="#e2e2e0" />
    <path d="M524.337 51.567h9.808V36.938h-9.808z" fill="#e8e9e6" />
    <path d="M524.337 118.583h9.808V51.567h-9.808z" fill="#efefed" />
    <path
      d="M524.337 36.939H499.16v-3.523h25.178v3.523m0-8.412H499.16v-4.375h25.177v4.375"
      fill="#f9f9f8"
    />
    <path d="M499.159 33.416h25.178v-4.89H499.16z" fill="#e6e6e4" />
    <path d="M499.159 51.567h25.178V36.938H499.16z" fill="#ededeb" />
    <path d="M499.159 118.583h25.178V51.567H499.16z" fill="#f4f4f2" />
    <path
      d="M315.353 36.939h-8.857v-3.523h8.857v3.523m0-8.412h-8.857v-4.375h-5.653 14.51v4.375"
      fill="#f9f9f8"
    />
    <path d="M306.496 33.416h8.857v-4.89h-8.857z" fill="#e6e6e4" />
    <path d="M306.496 51.567h8.857V36.938h-8.857z" fill="#ededeb" />
    <path
      d="M315.353 202.567h-8.857v-151h8.857-2.738l2.738 5.961v145.039"
      fill="#f4f4f2"
    />
    <path d="m315.353 57.528-2.738-5.961h2.738v5.961" fill="#f9f9f8" />
    <path
      d="M306.496 36.939h-5.653v-3.523h5.653v3.523m0-8.412h-5.653v-4.375h-5.652 11.305v4.375"
      fill="#f3f2f1"
    />
    <path d="M300.843 33.416h5.653v-4.89h-5.653z" fill="#e2e2e0" />
    <path d="M300.843 51.567h5.653V36.938h-5.653z" fill="#e8e9e6" />
    <path d="M300.843 202.567h5.653v-151h-5.653z" fill="#efefed" />
    <path
      d="M300.843 36.939h-8.858v-3.523h8.858v3.523m0-8.412h-8.858v-4.375h-5.652 14.51v4.375"
      fill="#f9f9f8"
    />
    <path d="M291.985 33.416h8.858v-4.89h-8.858z" fill="#e6e6e4" />
    <path d="M291.985 51.567h8.858V36.938h-8.858z" fill="#ededeb" />
    <path d="M300.843 202.567h-7.514v-151h-1.344 8.858v151" fill="#f4f4f2" />
    <path d="M291.985 202.567h1.344v-151h-1.344z" fill="#e6e6e4" />
    <path
      d="M291.985 36.939h-5.652v-3.523h5.652v3.523m0-8.412h-5.652v-4.375h-5.652 11.304v4.375"
      fill="#f3f2f1"
    />
    <path d="M286.333 33.416h5.652v-4.89h-5.652z" fill="#e2e2e0" />
    <path
      d="M291.472 202.567h-5.139V36.939h5.652v14.628h-.513v151"
      fill="#e8e9e6"
    />
    <path d="M291.472 202.567h.514v-151h-.514z" fill="#e2e2e0" />
    <path
      d="M276.843 202.567h-5.019V33.416h14.51v3.523h-9.491v165.628m9.49-174.04h-14.509v-4.375h14.509v4.375"
      fill="#f9f9f8"
    />
    <path d="M271.824 33.416h14.51v-4.89h-14.51z" fill="#e6e6e4" />
    <path d="M276.843 202.567h9.49V36.939h-9.49z" fill="#ededeb" />
    <path d="M93.778 320.21h115.824V24.258H93.778z" fill="#f3f2f1" />
    <path d="M209.601 320.21h3.179V24.258h-3.179z" fill="#dadbd8" />
    <path d="M104.507 309.481h94.366V34.987h-94.366z" fill="#fff" />
    <path
      d="m137.724 309.481 31.31-274.494h18.078l-31.31 274.494h-18.078m-15.654 0 31.309-274.494h9.038L131.11 309.48h-9.04"
      fill="#fafaf9"
    />
    <path
      d="M591.852 481.716c0 8.338-115.741 15.097-258.519 15.097-142.776 0-258.518-6.759-258.518-15.097 0-8.339 115.742-15.099 258.518-15.099 142.778 0 258.519 6.76 258.519 15.099"
      fill="#f3f2f1"
    />
    <path
      d="M530.728 481.716h-155.73c-9.269 0-16.353-7.585-15.74-16.853l21.726-329.427c.61-9.27 8.695-16.853 17.964-16.853h155.73c9.269 0 16.353 7.582 15.742 16.853l-21.727 329.427c-.612 9.268-8.696 16.853-17.965 16.853"
      fill="#17282f"
    />
    <path
      d="M563.516 207.175h-18.847c-2.237 0-3.946-1.831-3.8-4.067l1.882-28.535c.148-2.237 2.1-4.068 4.337-4.068h18.847c2.237 0 3.948 1.831 3.8 4.068l-1.882 28.535c-.148 2.236-2.1 4.067-4.337 4.067"
      fill="#17282f"
    />
    <path
      d="M439.704 115.25h-6.89v-1.334h6.89v1.333m-15.377.001h-25.068v-1.334h25.068v1.333m104.032 366.467H372.628c-9.27 0-16.352-7.585-15.741-16.853l21.726-329.427c.611-9.27 8.695-16.853 17.964-16.853h155.731c9.27 0 16.353 7.582 15.741 16.853l-21.726 329.427c-.612 9.268-8.695 16.853-17.964 16.853"
      fill="#406fbf"
    />
    <path
      d="M535.421 443.344H370.625c-5.268 0-9.293-4.31-8.946-9.578l18.914-286.79c.348-5.268 4.943-9.579 10.211-9.579H555.6c5.268 0 9.293 4.311 8.947 9.579l-18.915 286.79c-.348 5.269-4.941 9.578-10.21 9.578"
      fill="#fff"
    />
    <path
      d="M504.7 137.397h-32.725l-79.271 305.947h32.725L504.7 137.397m28.821 0H517.16l-79.271 305.947h16.363l79.27-305.947"
      fill="#fafaf9"
    />
    <path
      d="m511.784 348.55-35.565-134.554c-1.964-7.428-9.647-11.899-17.075-9.936l-84.153 22.244-11.226 175.817 138.084-36.498c7.428-1.963 11.899-9.647 9.935-17.074"
      fill="#ededeb"
    />
    <path
      d="M451.315 469.257c-5.756 0-10.114-4.665-9.734-10.421.379-5.754 5.352-10.42 11.108-10.42 5.755 0 10.114 4.666 9.734 10.42-.379 5.756-5.354 10.421-11.108 10.421m22.26-337.498c-2.382 0-4.184-1.93-4.027-4.31.157-2.381 2.215-4.31 4.595-4.31s4.182 1.929 4.025 4.31c-.157 2.38-2.213 4.31-4.593 4.31m.44-6.682c-1.31 0-2.44 1.062-2.527 2.372a2.19 2.19 0 0 0 2.213 2.37c1.31 0 2.44-1.062 2.527-2.37.087-1.31-.904-2.372-2.213-2.372"
      fill="#5d87cd"
    />
    <path
      d="M473.701 129.819a2.19 2.19 0 0 1-2.213-2.37c.087-1.31 1.217-2.372 2.527-2.372 1.309 0 2.3 1.062 2.213 2.372-.087 1.308-1.217 2.37-2.527 2.37"
      fill="#759ed9"
    />
    <path
      d="M225.759 110.808c4.973-12.223 3.184-27.112-10.915-30.445-14.099-3.334-26.21 7.445-25.655 20.778.556 13.334 9.37 24.444-6.037 34.111-15.407 9.667-13.74 38.795 16.037 33.175 29.778-5.619 13.667-20.928 13.667-20.928s-7.301 5.952-9.636-.102c-3.024-7.836 16.715-22.278 22.539-36.589"
      fill="#17282f"
    />
    <path
      d="m173.437 150.915-.652-.146c1.988-8.885 5.103-11.182 8.4-13.614 3.155-2.328 6.418-4.734 8.939-13.48 1.187-4.116.43-8.674-.3-13.08-.933-5.615-1.896-11.422.996-16.618l.583.323c-2.779 4.995-1.835 10.684-.922 16.185.743 4.48 1.512 9.114.284 13.375-2.585 8.963-5.94 11.437-9.184 13.83-3.194 2.357-6.212 4.583-8.144 13.225"
      fill="#17282f"
    />
    <path
      d="M263.624 177.97c.099.454.25 1.14.404 1.742l.467 1.883c.325 1.264.681 2.532 1.053 3.798.764 2.531 1.565 5.067 2.452 7.578a162.44 162.44 0 0 0 6.02 14.838l1.69 3.51a5.295 5.295 0 0 0 1.189 1.668c1.062 1.013 2.953 1.836 5.176 2.292 4.461.914 9.992.629 15.053-.066l1.808 5.663c-1.468.66-2.8 1.141-4.236 1.608a42.191 42.191 0 0 1-4.339 1.19c-2.954.651-6.049 1.031-9.358.865-3.275-.183-6.946-.908-10.367-3.263-1.695-1.159-3.204-2.768-4.292-4.557l-.21-.331-.135-.235-.27-.472-.538-.941-1.079-1.885a130.825 130.825 0 0 1-7.337-15.759c-1.02-2.72-2.012-5.457-2.823-8.268-.41-1.405-.804-2.819-1.168-4.25a59.257 59.257 0 0 1-.976-4.518l11.816-2.09"
      fill="#f9a8b3"
    />
    <path
      d="M253.277 159.336c13.455 6 13.371 26.156 13.371 26.156l-14.163 8.521s-10.648-15.786-9.873-22.956c.805-7.481 3.093-15.1 10.665-11.721"
      fill="#406fbf"
    />
    <path d="m258.239 190.552.064-.039-.064.039" fill="#d8898c" />
    <path
      d="M255.305 192.315c.991-4.94 1.742-9.114 2.306-12.635 1.584 3.42 1.55 7.53.692 10.833l-.064.039-2.934 1.763"
      fill="#3563b7"
    />
    <path
      d="m295.73 215.79 9.465-4.72.114 12.378s-7.069 1.23-11.782-3.735l2.202-3.922"
      fill="#f9a8b3"
    />
    <path
      d="m315.377 213.259-1.628 9.205-8.44.984-.114-12.377 10.182 2.188M209.083 471.876l11.706 1.119 5.455-27.232-11.707-1.119-5.454 27.232m-76.768-31.932 9.528 6.94 15.878-22.535-9.529-6.938-15.877 22.533"
      fill="#f9a8b3"
    />
    <path
      d="m145.3 442.55-7.852-10.742a.936.936 0 0 0-1.21-.287l-9.396 4.988c-.974.518-1.254 1.886-.58 2.776 2.803 3.7 4.342 5.343 7.794 10.067 2.124 2.908 6.396 9.65 9.33 13.663 2.869 3.926 7.058 1.326 6.197-.608-3.866-8.672-3.978-13.754-3.703-17.829a3.097 3.097 0 0 0-.58-2.027m77.035 26.908h-14.051c-.512 0-.973.354-1.11.854l-3.055 11.118c-.318 1.152.532 2.211 1.714 2.223 4.951.045 7.299.022 13.478.022h18.885c5.135 0 6.21-5.19 4.084-5.66-9.525-2.108-15.016-5.015-17.955-7.79a2.888 2.888 0 0 0-1.99-.767"
      fill="#17282f"
    />
    <path d="M211.5 459.807h-.004l.896-4.48h.005l-.897 4.48" fill="#c3c5c2" />
    <path
      d="M223.43 459.807H211.5l.897-4.48 11.868.305-.834 4.175"
      fill="#d8898c"
    />
    <path
      d="m140.004 429.032-.007-.005 1.527-2.166h.01l-1.53 2.171"
      fill="#b6b8b6"
    />
    <path
      d="M141.533 426.861h-.009l.988-1.4.005.004-.984 1.396"
      fill="#babcba"
    />
    <path
      d="m149.53 435.968-9.526-6.936 1.53-2.17.983-1.397 9.211 7.386-2.199 3.117"
      fill="#d8898c"
    />
    <path
      d="M258.887 169.953c-.828 8.999-3.824 26.644-12.555 61.196l-47.924-4.145c2.307-17.999 3.39-29.643-1.119-63.697-.63-4.771 2.911-9.098 7.715-9.368 3.448-.196 7.555-.311 11.68-.154 8.099.31 14.73.624 21.763 1.883 4.214.756 8.728 1.848 12.398 2.815a10.84 10.84 0 0 1 8.042 11.47"
      fill="#406fbf"
    />
    <path
      d="M199.885 187.636a95.459 95.459 0 0 0-.06-.821c2.064-2.055 4.772-4.872 7.292-7.878-1.281 5.626-3.93 8.41-7.232 8.699"
      fill="#3563b7"
    />
    <path
      d="M246.332 231.15s16.216 71.73 5.39 122.3c-7.249 33.867-23.362 102.287-23.362 102.287l-20.441-.525s9.76-71.801 13.852-100.039c6.998-48.301-10.532-127.058-10.532-127.058l35.093 3.034"
      fill="#17282f"
    />
    <path
      d="m231.243 456.796-25.252-.644 1.46-7.76 25.765 1.222-1.973 7.182"
      fill="#406fbf"
    />
    <path
      d="M235.855 230.387s-9.514 76.069-30.132 117.6c-16.655 33.544-51.5 86.864-51.5 86.864l-12.73-10.206s21.787-58.967 34.166-81.249c30.35-54.636 5.546-96.33 22.749-116.392l37.447 3.383"
      fill="#17282f"
    />
    <path
      d="M499.99 316.759 273.34 411.98c-7.083 2.976-15.313-.384-18.288-7.468l-53.908-128.309c-2.976-7.084.384-15.312 7.467-18.29l226.652-95.222c7.082-2.975 15.312.385 18.288 7.468l53.906 128.31c2.976 7.083-.384 15.313-7.466 18.289"
      fill="#406fbf"
    />
    <path
      d="M267.968 413.066c-5.463 0-10.677-3.222-12.916-8.553l-9.583-22.806-22.049-52.482-5.5-13.089-16.776-39.932a13.843 13.843 0 0 1-1.085-5.385c.005-5.458 3.226-10.666 8.552-12.904l226.652-95.223a13.839 13.839 0 0 1 5.37-1.084c5.463 0 10.678 3.221 12.918 8.552l53.906 128.31a13.837 13.837 0 0 1 1.086 5.385c-.004 4.361-2.063 8.564-5.607 11.217a13.837 13.837 0 0 1-2.945 1.687l-134.38 56.456-2.756 1.158-5.771 2.424-.793.334-82.951 34.85a13.84 13.84 0 0 1-5.372 1.085m35.27-73.049c-.323 0-.654.063-.97.196l-43.464 18.26a2.534 2.534 0 0 0-1.35 3.308l3.293 7.838a2.531 2.531 0 0 0 3.306 1.35l43.464-18.26a2.534 2.534 0 0 0 1.351-3.308l-3.293-7.838a2.535 2.535 0 0 0-2.336-1.546m56.561-23.764a2.51 2.51 0 0 0-.97.196l-43.466 18.262a2.535 2.535 0 0 0-1.35 3.308l3.293 7.837a2.535 2.535 0 0 0 3.306 1.35l43.466-18.26a2.533 2.533 0 0 0 1.35-3.307l-3.293-7.839a2.538 2.538 0 0 0-2.336-1.547m56.56-23.762a2.5 2.5 0 0 0-.97.196l-43.465 18.261a2.534 2.534 0 0 0-1.35 3.308l3.293 7.837a2.532 2.532 0 0 0 3.307 1.351l43.464-18.261a2.532 2.532 0 0 0 1.35-3.307l-3.293-7.839a2.535 2.535 0 0 0-2.336-1.546m-142.64-40.796c-.865 0-1.743.168-2.587.522l-44.372 18.642c-3.409 1.432-5.028 5.394-3.594 8.805l9.349 22.252c1.077 2.567 3.588 4.117 6.217 4.117.866 0 1.743-.166 2.587-.522l44.372-18.642c3.41-1.432 5.028-5.394 3.596-8.805l-9.35-22.252c-1.078-2.567-3.589-4.117-6.218-4.117m199.2 17.033a2.5 2.5 0 0 0-.97.196l-43.465 18.261a2.536 2.536 0 0 0-1.35 3.308l3.293 7.838a2.535 2.535 0 0 0 2.336 1.546c.324 0 .655-.064.972-.197l43.464-18.26a2.534 2.534 0 0 0 1.35-3.307l-3.293-7.837a2.536 2.536 0 0 0-2.337-1.548m-67.4-71.388c-2.427 0-4.893.473-7.272 1.472-9.568 4.02-14.067 15.036-10.047 24.605 3.022 7.192 9.994 11.519 17.335 11.519 2.427 0 4.895-.473 7.272-1.472a18.714 18.714 0 0 0 9.599-9.06 18.736 18.736 0 0 0 13.19-.515c9.57-4.02 14.068-15.037 10.047-24.606-3.021-7.191-9.993-11.518-17.335-11.518a18.73 18.73 0 0 0-7.27 1.471 18.722 18.722 0 0 0-9.6 9.063 18.776 18.776 0 0 0-5.919-.959"
      fill="#3b69bb"
    />
    <path
      d="M238.733 306.033c-2.629 0-5.14-1.55-6.217-4.117l-9.35-22.252c-1.433-3.41.186-7.373 3.595-8.805l44.372-18.642a6.662 6.662 0 0 1 2.587-.522c2.63 0 5.14 1.55 6.219 4.117l9.349 22.252c1.432 3.41-.185 7.373-3.596 8.805l-44.372 18.642a6.638 6.638 0 0 1-2.587.522"
      fill="#5681ca"
    />
    <path
      d="M263.081 371.165a2.53 2.53 0 0 1-2.334-1.546l-3.294-7.838a2.534 2.534 0 0 1 1.351-3.308l43.464-18.26a2.535 2.535 0 0 1 3.307 1.35l3.293 7.838a2.534 2.534 0 0 1-1.35 3.308l-43.465 18.26a2.511 2.511 0 0 1-.972.196m56.562-23.762a2.535 2.535 0 0 1-2.336-1.547l-3.294-7.837a2.535 2.535 0 0 1 1.351-3.308l43.465-18.262c.316-.132.646-.196.971-.196.988 0 1.93.584 2.336 1.547l3.293 7.839a2.533 2.533 0 0 1-1.35 3.306l-43.466 18.262a2.495 2.495 0 0 1-.97.196m56.56-23.763a2.533 2.533 0 0 1-2.335-1.547l-3.293-7.837a2.534 2.534 0 0 1 1.35-3.308l43.464-18.261a2.535 2.535 0 0 1 3.307 1.35l3.293 7.839a2.532 2.532 0 0 1-1.35 3.307l-43.464 18.261a2.526 2.526 0 0 1-.972.196m56.561-23.763c-.988 0-1.93-.582-2.336-1.546l-3.293-7.838a2.536 2.536 0 0 1 1.35-3.308l43.464-18.261a2.536 2.536 0 0 1 3.308 1.352l3.294 7.837a2.534 2.534 0 0 1-1.351 3.307l-43.464 18.26a2.508 2.508 0 0 1-.972.197"
      fill="#78a0da"
    />
    <path
      d="M428.327 225.361c-2.027 0-4.024-.329-5.92-.957a18.746 18.746 0 0 0 .448-15.545 18.745 18.745 0 0 0-11.416-10.56 18.722 18.722 0 0 1 9.6-9.063 18.739 18.739 0 0 1 7.27-1.47c7.342 0 14.314 4.326 17.335 11.517 4.021 9.569-.477 20.586-10.047 24.606a18.736 18.736 0 0 1-7.27 1.472"
      fill="#244d88"
    />
    <path
      d="M405.536 234.936c-7.341 0-14.313-4.327-17.335-11.519-4.02-9.569.479-20.585 10.047-24.605a18.747 18.747 0 0 1 7.272-1.472c2.027 0 4.024.33 5.919.959a18.745 18.745 0 0 1 11.416 10.56 18.746 18.746 0 0 1-.448 15.545 18.714 18.714 0 0 1-9.599 9.06 18.742 18.742 0 0 1-7.272 1.472"
      fill="#a1bfe8"
    />
    <path
      d="m155.557 436.263-16.324-12.366 4.006-7.17 17.357 13.472-5.039 6.064m42.376-212.133-2.532 4.102c-.197.32.168.693.722.74l50.261 4.348c.432.037.808-.144.859-.413l.804-4.252c.054-.295-.298-.579-.771-.62l-48.53-4.198c-.358-.03-.686.088-.813.292"
      fill="#406fbf"
    />
    <path
      d="M246.497 233.325c-.037 0-.076-.002-.113-.005l-10.793-.933-38.735-3.351-.733-.064c-.45-.039-.775-.292-.776-.557 0-.06.017-.123.054-.183l2.532-4.103c.114-.182.387-.296.699-.296.037 0 .075.002.113.004l48.531 4.198c.44.038.775.286.776.557a.332.332 0 0 1-.005.063l-.804 4.252c-.047.245-.363.418-.746.418"
      fill="#254583"
    />
    <path
      d="m203.981 230.12-1.298-.113c-.26-.022-.446-.171-.414-.334l1.092-5.52c.032-.161.27-.276.528-.254l1.3.113c.259.023.446.172.414.333l-1.092 5.522c-.032.162-.27.276-.53.253m38.709 3.348-1.3-.112c-.258-.023-.443-.173-.411-.335l1.09-5.52c.034-.162.271-.276.53-.254l1.3.113c.258.023.444.173.412.335l-1.091 5.521c-.032.161-.27.275-.53.252m-19.354-1.675-1.3-.112c-.259-.021-.444-.173-.412-.334l1.092-5.52c.032-.162.27-.275.528-.254l1.3.112c.259.023.444.174.412.335l-1.09 5.521c-.033.162-.271.276-.53.252m-78.117 212.792c.904 1.983 1.909 3.247 2.736 3.415.357.07.686-.05 1.017-.37.19-.185.184-.34.163-.442-.188-.893-2.548-2.037-3.916-2.603zm3.105 3.89c-.15.02-.305.013-.463-.019-1.453-.295-2.69-2.761-3.3-4.205l.048-.252.25-.056c.452.168 4.426 1.693 4.732 3.149.046.222.033.555-.295.872-.305.299-.63.47-.972.512"
      fill="#406fbf"
    />
    <path
      d="M145.287 444.096c1.328.379 3.817.404 4.434-.265.103-.112.196-.286.004-.627a.944.944 0 0 0-.664-.485c-1.129-.253-2.988.858-3.774 1.377zm3.158.677c-1.322.166-2.997-.053-3.764-.4l-.136-.189.094-.212c.116-.084 2.864-2.076 4.522-1.71.432.094.76.335.972.714.331.59.1.987-.068 1.17-.314.343-.918.538-1.62.627m77.09 26.159c1.513.284 4.313.652 5.465-.219.32-.243.487-.56.51-.97.011-.19-.063-.266-.127-.308-.718-.488-3.732.566-5.848 1.497zm3.034.799c-1.349 0-2.817-.26-3.836-.486l-.181-.202.133-.236c.578-.272 5.675-2.629 6.959-1.759.164.11.355.325.332.721a1.702 1.702 0 0 1-.695 1.315c-.636.481-1.636.647-2.712.647"
      fill="#406fbf"
    />
    <path
      d="M228.277 466.496c-1.766 0-2.797 2.925-3.138 4.098 1.661-.871 3.958-2.969 3.846-3.786-.008-.061-.032-.244-.513-.301zm-3.493 4.755-.15-.056-.078-.23c.05-.214 1.205-5.241 3.972-4.921.693.083.883.427.92.7.173 1.28-3.041 3.861-4.576 4.488l-.088.019"
      fill="#406fbf"
    />
    <path
      d="m203.724 173.515-.713 1-.776 1.141a74.26 74.26 0 0 0-1.534 2.367c-1.012 1.6-1.93 3.273-2.858 4.94a88.635 88.635 0 0 0-4.855 10.374c-1.427 3.547-2.553 7.192-3.51 10.878l-.625 2.608c-.013-.084-.076.204.07.713.26 1.023 1.024 2.533 1.978 3.97 1.932 2.937 4.539 5.855 7.231 8.68 2.721 2.823 5.624 5.563 8.577 8.271 2.962 2.704 6.032 5.318 9.127 7.916 3.07 2.618 6.255 5.167 9.376 7.674l-3.33 4.924c-7.173-4.247-13.995-8.855-20.657-13.891-3.336-2.507-6.596-5.143-9.753-7.997-3.167-2.859-6.224-5.887-9.037-9.614-1.386-1.898-2.766-3.93-3.732-6.817-.464-1.436-.824-3.219-.57-5.332l.048-.376.034-.203.066-.402.136-.807.274-1.616c.769-4.3 1.905-8.525 3.31-12.648a100.41 100.41 0 0 1 5.187-11.97c1-1.935 2.097-3.817 3.26-5.678a86.06 86.06 0 0 1 1.804-2.77l.983-1.37c.35-.476.65-.876 1.116-1.459l9.373 7.494"
      fill="#f9a8b3"
    />
    <path
      d="M202.532 154.715c-9.144 2.405-17.977 24.572-17.977 24.572l11.84 10.825S215 173.16 214.377 165.504c-.528-6.5-4.496-12.723-11.845-10.79"
      fill="#406fbf"
    />
    <path
      d="M216.776 153.71a9.1 9.1 0 0 0-.081-.251l.202.149c-.038.035-.08.07-.121.103"
      fill="#c3c5c2"
    />
    <path
      d="M216.684 153.785c-.585-.021-1.17-.038-1.753-.05 1.134-.131 1.753-.284 1.753-.284l.01.008c.029.084.057.168.082.252l-.092.074"
      fill="#111d23"
    />
    <path
      d="M225.833 175.397c-5.79-4.76-9.669-8.845-11.901-12.673-.399-1.639-1.033-3.18-1.903-4.483a13.942 13.942 0 0 1-.437-4.325c1.37 0 2.497-.084 3.339-.181.584.012 1.168.029 1.753.05 0 0 2.76 8.002 17.12 12.163 0 0-5.47 1.677-7.97 9.45"
      fill="#3563b7"
    />
    <path
      d="M213.932 162.724c-.89-1.527-1.519-3.012-1.903-4.483.87 1.303 1.504 2.844 1.903 4.483"
      fill="#3563b7"
    />
    <path
      d="M242.664 156.507a126.62 126.62 0 0 0-4.217-.839l-.072-.024.065-.147s1.212.171 4.113.834l.111.176"
      fill="#c3c5c2"
    />
    <path
      d="M241.296 174.953c-2.219-8.002-7.492-9.005-7.492-9.005 8.641-4.995 4.643-10.28 4.643-10.28 1.386.25 2.805.535 4.217.839 1.908 3.15 4.212 12.086-1.368 18.446"
      fill="#3563b7"
    />
    <path
      d="M216.684 153.45s-2.717.05-5.93.05c-1.927 2.979 4.723 11.223 15.098 19.75 2.5-7.773 7.952-7.302 7.952-7.302l-17.12-12.497m21.756 2.046s1.212.171 4.113.834c1.927 2.978 6.099 11.028.41 17.51-2.219-8.001-9.159-7.893-9.159-7.893l4.636-10.45"
      fill="#fff"
    />
    <path
      d="M220.96 129.468c.767 7.121 1.028 20.07-4.276 24.317 0 0 2.76 8.002 17.12 12.163 8.641-4.995 4.643-10.28 4.643-10.28-7.647-2.539-6.946-8.315-5.115-13.637l-12.372-12.563"
      fill="#f9a8b3"
    />
    <path
      d="m232.323 145.83-.003-.002c.017-.105.036-.21.056-.316-.019.105-.037.212-.053.317m1.004-3.804-1.732-1.758 1.733 1.757-.001.001"
      fill="#c3c5c2"
    />
    <path
      d="M232.32 145.828c-2.947-.69-7.208-3.75-7.255-6.9-.022-1.572.622-3.63.939-4.335l5.59 5.674 1.733 1.758c-.402 1.154-.743 2.328-.951 3.487-.02.105-.039.21-.056.316"
      fill="#d8898c"
    />
    <path
      d="M237.173 101.013c2.338-2.572 6.958.704 8.674 7.01 2.55 9.373-4.28 10.008-7.842 5.366-3.561-4.642-2.778-10.232-.832-12.376"
      fill="#17282f"
    />
    <path
      d="M244.403 117.372s3.425 1.092 6.57 3.385c-1.162-2.802-2.2-4.514-6.57-3.385"
      fill="#17282f"
    />
    <path
      d="M245.745 117.835s2.739-.615 5.76-.312c-1.886-1.428-3.26-2.172-5.76.312"
      fill="#17282f"
    />
    <path
      d="M214.696 118.343c2.97 11.374 3.984 16.252 10.961 21.017 10.494 7.17 23.814 1.033 24.302-10.999.438-10.829-4.492-27.609-16.662-29.974-11.99-2.334-21.572 8.582-18.601 19.956"
      fill="#f9a8b3"
    />
    <path
      d="m220.156 103.344-3.292 3.821c-2.047 2.376-5.664 2.644-8.04.598-2.376-2.046-2.644-5.664-.599-8.04l3.292-3.82c2.047-2.376 5.666-2.644 8.04-.599 2.376 2.047 2.644 5.665.599 8.04"
      fill="#fff"
    />
    <path
      d="m223.207 107.61-4.056 2.003c-3.406 1.679-7.567.267-9.247-3.138-1.68-3.407-.268-7.567 3.137-9.247l4.056-2c3.406-1.68 7.567-.27 9.247 3.137 1.68 3.406.268 7.567-3.137 9.246"
      fill="#406fbf"
    />
    <path
      d="M218.075 126.856s8.224-1.888 4.728-13.897c-.579-1.984.894-3.966 2.961-4.02 9.245-.243 17.896-2.183 16.679-7.542-1.54-6.777-24.591-12.036-28.776 1.556-8.171 1.927-8.482 18.028 4.408 23.903"
      fill="#17282f"
    />
    <path
      d="M218.124 124.479s3.427 1.093 6.57 3.385c-1.162-2.801-2.198-4.513-6.57-3.385"
      fill="#17282f"
    />
    <path
      d="M219.468 124.941s2.737-.614 5.76-.312c-1.888-1.426-3.261-2.172-5.76.312"
      fill="#17282f"
    />
    <path
      d="M212.805 127.277c1.119 2.164 3.134 3.612 5.051 4.188 2.885.87 4.387-1.696 3.636-4.45-.677-2.479-2.993-5.916-5.912-5.579-2.877.332-4.053 3.365-2.775 5.841"
      fill="#f9a8b3"
    />
    <path
      d="M233.95 117.952c.185.937.83 1.601 1.442 1.48.613-.12.961-.979.776-1.916-.184-.937-.83-1.599-1.443-1.479-.613.122-.96.978-.776 1.915m10.596-2.082c.184.938.831 1.6 1.443 1.48.613-.122.96-.978.776-1.915-.184-.939-.83-1.6-1.443-1.48-.613.12-.96.978-.776 1.916"
      fill="#17282f"
    />
    <path
      d="m245.06 114.059 2.125-1.091s-.81 1.985-2.125 1.09"
      fill="#17282f"
    />
    <path
      d="M240.903 118.207s2.384 3.722 4.517 5.325c-1.01 1.399-3.219 1.284-3.219 1.284l-1.298-6.61"
      fill="#f46878"
    />
    <path
      d="M237.868 129.285c-3.32 0-5.248-1.938-5.34-2.033l.003-.33.33.005c.026.025 2.544 2.538 6.668 1.726a.228.228 0 0 1 .274.184.233.233 0 0 1-.183.274 9.117 9.117 0 0 1-1.752.174m-10.283-14.966-.241-.068a.466.466 0 0 1-.157-.64c1.578-2.615 4.117-2.728 4.225-2.732.27.01.47.192.48.449a.466.466 0 0 1-.448.483c-.087.004-2.15.116-3.459 2.282a.469.469 0 0 1-.4.226m19.902-3.756-.227-.059c-2.23-1.245-4.116-.281-4.133-.272a.465.465 0 1 1-.435-.825c.095-.051 2.359-1.207 5.023.282a.467.467 0 0 1-.228.874"
      fill="#17282f"
    />
    <path
      d="m220.815 242.139 10.704.756-4.834 11.394s-8.908-2.817-9.27-9.336l3.4-2.814"
      fill="#f9a8b3"
    />
    <path
      d="m235.579 249.341-5.166 7.791-3.728-2.843 4.834-11.394 4.06 6.446"
      fill="#f9a8b3"
    />
    <path
      d="m234.465 116.143 2.124-1.091s-.81 1.985-2.124 1.09"
      fill="#17282f"
    />
  </svg>
);

export default IconPersonHoldCreditCard;
